<template>
  <fragment>
    <app-header title="Screen Tags"></app-header>

    <category-list :items="screenTags">
      <template #add>
        <validation-observer tag="div" class="row" @submit.native.prevent ref="validation">
          <validation-provider tag="div" class="col" name="name" rules="required">
            <label>Add Screen Tag</label>
            <c-input type="text" v-model="stateName" />
            <c-help-block/>
          </validation-provider>
          <div class="col-auto">
            <c-button
              validate
              variant="primary"
              @click="save"
              class="mt-label"
            >
              Add
            </c-button>
          </div>
        </validation-observer>
      </template>
      <template #archive="{ item }">
        <button type="button" class="btn btn-sm text-warning" @click="handleArchive(item)">
          <i class="far fa-trash-restore" v-if="item.archived"></i>
          <i class="far fa-archive" v-else></i>
        </button>
      </template>
    </category-list>
  </fragment>
</template>
<script>
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/settings/screen-tags'
import CategoryList from '@/components/settings/CategoryList'

export default {
  mixins: [
    stateMapper
  ],

  components: {
    CategoryList
  },

  created () {
    this.getScreenTags()
  },

  computed: {
    ...helperComputed
  },

  methods: {
    ...helperMethods,

    async save () {
      await this.storeScreenTag(this.screenTag)
      this.resetScreenTag()
      this.$refs.validation.reset()
    },

    async handleArchive (item) {
      if (item.archived) {
        await this.updateScreenTag(item)
        this.resetScreenTag()
        this.getScreenTags({ fresh: true })
      } else {
        await this.deleteScreenTag(item.id)
        await this.getScreenTags({ fresh: true })
      }
    }
  }
}
</script>
