<template>
  <div class="container my-4">

    <div class="card card-data-builder">
      <div class="card-body border-bottom">
        <slot name="add"></slot>
      </div>

      <ul id="horizontal-nav" class="nav nav-horizontal">
        <li class="nav-item" :class="{ active: !archived }"><a class="nav-link" href="#" @click="archived = false">Published</a></li>
        <li class="nav-item" :class="{ active: archived }"><a class="nav-link" href="#" @click="archived = true">Archived</a></li>
      </ul><!-- /#horizontal-nav -->

      <ul class="list-data no-links mt-0">
        <li v-for="item in filteredList" :key="item.id">
          <div class="row">
            <div class="col text-truncate">
              <p>{{ item.name }}</p>
            </div>
            <div class="col-auto">
              <slot name="archive" :item="item"></slot>
            </div>
          </div><!-- /.row -->
        </li>
      </ul><!-- /.list-data -->
    </div><!-- /.card -->

  </div>
</template>
<script>
export default {
  props: {
    items: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      archived: false
    }
  },

  computed: {
    filteredList () {
      return this.items.filter(({ archived }) => archived === this.archived)
    }
  }
}
</script>
