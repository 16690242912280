<template>
  <div>
    <app-header title="Settings" />

    <div class="container container-fluid mt-4 mb-4">
      <ul class="nav nav-grid">
        <li class="nav-item col-12 col-sm-6 col-md-4 col-xl-3">
          <router-link class="nav-link" :to="{ name: 'settings.screen-tags' }">
            <i class="far fa-tags"></i>
            <div>
              Screen Tags
              <span class="description">Tags to group screens together.</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item col-12 col-sm-6 col-md-4 col-xl-3">
          <router-link class="nav-link" :to="{ name: 'settings.media-owner-tags' }">
            <i class="far fa-tags"></i>
            <div>
              Media Owner Tags
              <span class="description">Tags to group media owners together.</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
